import React, { useState } from 'react'
import Layout from 'layouts/en'
import Seo from 'components/EN/Seo'
import InfoCard from 'components/Form/InfoCard'
import Form from 'components/Form'
import TabCard from 'components/EN/TabCard'
import { tabs } from 'components/Form/data'
import { notification } from 'sensd'
import 'sensd/dist/sensd.css'
import classnames from 'classnames'
import * as styles from './index.module.less'
import { fetchParadeI18n } from 'api/user'
import { saTrackParadeSubmitForm, _cookie } from 'utils/statistics'
// 加载旋转图标组件
const LoadingSpinner = () => <div className={styles.loading} />

// 成功打钩图标组件
const SuccessCheck = () => (
  <div className="text-center px-[38px] lg:px-0">
    <div className={classnames(styles.success, 'mx-auto')} />
    <div>
      <div className="mt-[30px] leading-[24px] lg:leading-[40px] text-[#2c2c45] font-bold text-[22px] lg:text-[36px]">
        Thank You for Your Interest in Sensors Data
      </div>
      <div className=" text-[#666] leading-[16px] lg:leading-[24px] text-[12px] lg:text-[18px]">
        We'll Reach Out to You within 24 Hours. In the Meantime, Feel Free to Explore Further.
      </div>
    </div>
  </div>
)
// 提交后展示框
const SubmitModal = ({ isLoading, visible }) => {
  return (
    <div className={classnames('bg-[#fff] rounded-[6px] lg:mt-[108px] lg:w-[1060px] mt-[36px] ', { hidden: !visible })}>
      <div className="lg:h-[674px] flex flex-col ">
        <div className="flex-1 flex justify-center items-center pt-[34px] lg:pt-0 pb-[26px] lg:pb-0">
          {/* 根据 isLoading 状态动态切换加载或者成功图标 */}
          {isLoading ? <LoadingSpinner /> : <SuccessCheck />}
        </div>
        <hr className="mx-[44px] lg:mx-0" />
        <div className="flex-1 ">
          <div className="pt-[42px] pb-[42px] lg:pb-0 lg:flex lg:justify-center">
            {tabs.map((item) => {
              return <TabCard className="lg:mx-[15px]" {...item} key={item?.title} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const Parade = (props) => {
  // 表单显隐
  const [visible, setVisible] = useState(false)
  // 加载画面
  const [isLoading, setIsLoading] = useState(false)
  const onSubmit = async (values) => {
    if (visible) return
    try {
      // 获取cookie中utm参数 进行上报写入 crm 有值 上传2 没值上传1
      let utm_source = !!JSON.parse(_cookie.getItem('sensorsdata2015jssdkcross')).props?.['$latest_utm_source']
        ? '2'
        : '1'
      setIsLoading(true)
      setVisible(true)
      // 等待2s
      const waitTime = new Promise((resolve) => {
        setTimeout(resolve, 2000)
      })
      const res = await Promise.all([fetchParadeI18n({ ...values, source: utm_source }), waitTime])
      if (res[0].status === 200) {
        // 埋点采集
        // saTrackParadeSubmitForm(document.referrer)
        setIsLoading(false)
      }
    } catch (err) {
      console.error(err)
      setVisible(false)
      // message.error({ content: 'Submission failed, please try again later.', duration: 200 })
      notification['error']({
        message: 'Submission failed, please try again later.',
      })
    }
  }
  return (
    <Layout {...props} showLangDropdown={true} hideFooter>
      <Seo
        lang="en"
        title="Contact Us - Tailored Business Solutions | SensorsData"
        description="Fill out the contact form to learn how SensorsData can help you maximize the value of your data to your business."
        keywords="SensorsData,Contact,Support,Consulting Services"
      >
        <link rel="canonical" href="https://www.sensorsdata.com/form/parade/" />
      </Seo>
      <main
        className={classnames(
          'min-h-[100vh] min-w-[100vw] pb-[20px] lg:pt-[64px]  lg:flex lg:justify-center lg:items-center',
          styles.root,
        )}
      >
        <div className="lg:w-fit pt-[4rem] lg:pt-0 px-[20px] lg:px-0 mx-auto">
          {visible ? (
            <SubmitModal isLoading={isLoading} visible={visible} />
          ) : (
            <div className="mt-[40px] lg:mt-[68px] lg:flex">
              {/* left */}
              <div className="flex-1 lg:w-[550px] lg:mr-[76px] flex flex-col ">
                <div>
                  <h2 className="lg:whitespace-pre text-center lg:text-left text-[#2C2C45] lg:leading-[42px] lg:text-[36px] text-[32px] leading-[34px] font-bold mb-0">
                    {'Discover Tailored Solutions\nfor Business\nwith Our Consultants'}
                  </h2>
                  <div className="mt-[10px] lg:mt-[30px] leading-[24px] text-[#666] text-[14px] lg:text-[18px] text-center lg:text-left">
                    Maximize the Value of Data for Your Business
                  </div>
                </div>
                <div className="lg:block hidden ">
                  <InfoCard className="bg-[rgba(255,255,255,0.4)] w-full rounded-[4px] mt-[64px] px-[30px] pt-[40px] pb-[22px]" />
                </div>
              </div>
              {/* right */}
              <div className="mt-[20px] lg:mt-0 flex-1">
                <Form onSubmit={onSubmit} disabled={visible} />
              </div>
              <div className="mt-[20px] lg:hidden">
                <InfoCard className="bg-[rgba(255,255,255,0.4)] w-full rounded-[4px] px-[20px] py-[30px] lg:px-[30px] lg:py-[40px]" />
              </div>
            </div>
          )}
        </div>
      </main>
    </Layout>
  )
}

export default Parade
