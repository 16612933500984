import React from 'react'
import * as styles from './index.module.less'
import classnames from 'classnames'

const TabCard = ({ className, title, desc, link, img, text }) => {
  const handleClick = () => {
    window.location.href = link
  }
  return (
    <div
      className={classnames(
        'lg:w-[270px] lg:h-[266px]  text-left lg:text-center px-[20px] lg:px-[15px] lg:pt-[24px] flex items-center lg:inline-block',
        styles.card,
        className,
      )}
      onClick={handleClick}
    >
      <div>
        <img className="lg:w-[116px] lg:h-[94px] w-[77px] h-[63px]" src={img} alt={title} />
      </div>
      <div className="flex-1 ml-[14px] lg:ml-0">
        <div className="text-[22px] leading-[24px] font-bold text-[#2c2c45] mt-[10px]">{title}</div>
        <div className="mt-[12px] leading-[16px] text-[#666] text-[14px]">{desc}</div>
        <div className={classnames('mt-[20px] text-[14px] text-transparent font-medium', styles.text)}>{text}</div>
      </div>
    </div>
  )
}

export default TabCard
