import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface UnderLineProps {
  className?: string
  title?: string
  desc?: string
  icon?: string
  iconHeight?: string
  iconWidth?: string
  iconArrow?: boolean
  descLink?: string
}

const UnderLine: React.FC<UnderLineProps> = ({
  className,
  icon,
  title,
  desc,
  iconArrow,
  iconWidth,
  iconHeight,
  descLink,
}) => {
  const UnderLineStyle: any = {
    '--img': `url(${icon})`,
    '--hegiht': iconHeight,
    '--width': iconWidth,
  }
  return (
    <div className={classnames(className)} style={UnderLineStyle}>
      <h3
        className={classnames('text-[#2C2C45] leading-[24px] font-bold text-[20px] lg:text-[22px] flex items-center', {
          [styles['icon']]: icon,
        })}
      >
        {title}
      </h3>
      <hr className="mt-[10px] mb-[8px]" />
      {!!desc && (
        <div
          className={classnames(' mb-0  ', {
            [styles.iconArrow]: iconArrow,
          })}
        >
          <a
            className="lg:text-[22px] text-[20px] leading-[24px] text-[#666] lg:hover:text-[#04CB94] lg:cursor-pointer"
            href={descLink}
          >
            {desc}
          </a>
        </div>
      )}
    </div>
  )
}

export default UnderLine
