import React from 'react'
import UnderLine from '../UnderLine'
import classnames from 'classnames'
import * as styles from './index.module.less'

import emailIcon from 'assets/images/form/parade/email.png'
import wordIcon from 'assets/images/form/parade/word.png'
import qrCode from 'assets/images/form/parade/qrcode.png'

interface InfoCardProps {
  className?: string
}

const InfoCard: React.FC<InfoCardProps> = ({ className }) => {
  const arr = new Array(8).fill('')
  return (
    <div className={classnames(styles.infoCard, className)}>
      <div className="lg:flex">
        <div className="flex-1 lg:mr-[32px]">
          <UnderLine
            title="Email"
            desc="contact@sensorsdata.com"
            icon={emailIcon}
            iconWidth="30px"
            iconHeight="24px"
            descLink="mailto:contact@sensorsdata.com"
          />
          <UnderLine
            className="mt-[25px]"
            title="Technical help"
            desc="Read documentation"
            iconArrow
            icon={wordIcon}
            iconWidth="26px"
            iconHeight="31px"
            descLink="https://manual.sensorsdata.cn/sa/3.0/en/guide-127172823.html"
          />
        </div>
        <div className={classnames('w-[176px] h-[176px] lg:block hidden', styles.qrCode)}></div>
      </div>
      <div className="lg:mt-[24px] mt-[18px]">
        <UnderLine title="Trusted by Over 2000 Leading Brands" />
        <div className="flex flex-wrap mt-[8px] lg:mt-[24px]">
          {arr.map((item, index) => (
            <div className={classnames('mb-[12px] lg:mb-[18px] mr-[8px] lg:mr-[18px]', styles.logoCard)} key={index}>
              <img className="w-full" src={require(`assets/images/form/parade/logo_0${index + 1}.png`).default} />
            </div>
          ))}
        </div>
      </div>
      <div className="mt-[18px] text-center lg:hidden">
        <div className="mb-0">
          <img className="w-[152px]" src={qrCode} alt="" />
        </div>
        <div className="text-[14px] text-[#666] leading-[16px] mt-[10px] mb-0">
          Save and scan our WhatsApp QR code to contact us directly.
        </div>
      </div>
    </div>
  )
}

export default InfoCard
